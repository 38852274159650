import { Component } from 'react';
import { SHr, SIcon, SLoad, SText, STheme, SView } from 'servisofts-component';
import Parent from "..";
import SSocket from 'servisofts-socket'
import Model from '../../../../../Model';
import { connect } from 'react-redux';

class BtnSincronizar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presiono: false
    };
  }
  getContent = () => {
    if (this.state.loading) return <SLoad />
    return <SView col={"xs-12"} height center row>
      <SIcon name={"Reload"} fill={this.state.presiono ? "#FF5638" : STheme.color.lightGray} height={18} width={18} />
      <SView width={8} />
      <SText color={this.state.presiono ? "#FF5638" : STheme.color.lightGray} bold>Sincronizar usuarios al molinete</SText>
    </SView>
  }

  discor(info) {
    let usuario = Model.usuario.Action.getUsuarioLog();
    let _mensaje = `${info}\nUsuario: ${usuario.Nombres} ${usuario.Apellidos}\nSucursal: ${this.props.sucursal}`;
    console.log("s" + JSON.stringify(usuario))
    const url = "https://discord.com/api/webhooks/1321199346339024926/rsSy3FBsDU0sr-0Nw9uj6sZxR8iFUGdG1n1dvybRtL0Fkl8o0iWvGN5lsJZ52iYFtB7W";
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: usuario.Nombres + " " + usuario.Apellidos,
        avatar_url: " " + SSocket.api.root + "usuario/" + usuario.key,
        content: _mensaje
      })
    })
      .then(response => {
        if (response.ok) {
          console.log("Mensaje enviado exitosamente");
        } else {
          console.error("Error en la respuesta del servidor", response.status, response.statusText);
        }
      })
      .catch(error => console.error("Error al enviar el mensaje: ", error.message));
  }

  render() {
    return (
      <SView col={"xs-12"} center
        onPress={async () => {
          // const isConnected = this.props.testConnection();
          // console.log("hijo " + JSON.stringify(this.props.dispositivo))
          // if (!isConnected) {
          //   alert("❌ ¡Error de conexión! 🚫\n\nPor favor, presione el botón 'Test Connection molinete' 🔄");
          //   return;
          // }

          if (this.state.loading) return;
          this.setState({ loading: true });
          this.setState({ presiono: true });

          Parent.Actions.sincronizarMolinete(this.props.dispositivo?.key, this.props.key_sucursal, (5 * 60 * 1000)).then((resp) => {
            this.setState({ loading: false });
            if (resp.estado == "exito") {
              this.setState({ loading: false });
              alert("✅ ¡SINCRONIZACIÓN EXITOSA! La sucursal " + this.props.sucursal + " se ha sincronizado correctamente.");
              this.discor("SINCRONIZACIÓN EXITOSA ✅: La conexión fue exitosa para la sucursal " + this.props.sucursal);
            }
          }).catch((e) => {
            this.setState({ loading: false });

            alert("🔁 SINCRONIZACIÓN COMPLETADA SIN CAMBIOS\nNo se detectaron modificaciones en los usuarios de la sucursal " + this.props.sucursal + ".");
            this.discor("🔁 SINCRONIZACIÓN COMPLETADA SIN CAMBIOS: No se detectaron modificaciones en los usuarios de la sucursal " + this.props.sucursal + ".");
          })

          SSocket.sendPromise({
            component: "zkteco",
            type: "sincronizarLog",
            estado: "cargando",
            key_sucursal: this.props.key_sucursal,
          }, 10000)
        }} >
        <SHr />
        {this.getContent()}
        <SHr />
      </SView>
    );
  }
}


const initStates = (state) => {
  return { state }
};
export default connect(initStates)(BtnSincronizar);