
import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SLoad, SNavigation, SPage, STable2, SText, STheme, SView } from 'servisofts-component';
import Parent from "..";
import Model from '../../../../../Model';
import FloatButtomSyncUsuario from '../../../../../Components/FloatButtomSyncUsuario';
class DispositivoUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.key = SNavigation.getParam("key");
  }


  mensajeLoad(_texto) {
    return <SView col={"xs-12"} flex center  >
      <SView col={"xs-12"} row >
        <SView flex style={{ alignItems: "flex-end" }}>
          <SText><SLoad />       </SText>
        </SView>
        <SView flex style={{ alignItems: "flex-start" }}>
          <SText color='cyan'>Cargando server {_texto}..</SText>;
        </SView>
      </SView>
    </SView>
  }

  getData() {
    if (!this.key) return;
    if (this.state.data) return this.state.data;
    if (this.state.load) return;
    this.state.load = true;

    Parent.Actions.getUsuariosActivos(this.key).then((resp) => {
      this.state.load = false;
      if (resp.estado == "exito") {
        this.setState({ data: resp.data })
      }
    }).catch((e) => {
      this.state.load = false;
      console.log(e);
    })
  }

  getTable() {
    var data = this.getData();
    if (!data) return this.mensajeLoad("calistenia - Usuarios activos")

    var usrs = Model.usuario.Action.getAll();
    if (!usrs) return this.mensajeLoad("usuarios")

    return <STable2
      rowHeight={30}
      limit={100}
      header={[
        { key: "index", label: "#", width: 30 },
        { key: "codigo", label: "Pin", width: 50 },
        {
          key: "key_usuario-2", label: "CI", width: 90,
          render: (itm) => {
            var usuario = usrs[itm];
            return usuario?.CI
          },
        },
        {
          key: "key_usuario", label: "Usuario", width: 270,
          render: (itm) => {
            var usuario = usrs[itm];
            return usuario?.Nombres + " " + usuario?.Apellidos
          },
        },
        {
          key: "key_usuario-3", label: "Telefono", width: 100,
          render: (itm) => {
            var usuario = usrs[itm];
            return usuario?.Telefono
          },
        },
        {
          key: "key_usuario-1", label: "Historial", width: 90,
          component: (e) => <SView center style={{ borderWidth: 1, borderColor: "yellow", borderRadius: 4, padding: 2 }}
            onPress={() => { window.open("/ClientePerfilPage?key=" + e) }}>{!e ? null : <SText color="yellow">Paquetes</SText>}</ SView>
        },
        {
          key: "huellas-cant", label: "Cant. Huellas", width: 80,
          sumar: true,
          render: (arr) => {
            if (!arr) return 0;
            let cantidad = 0;
            arr.map(a => {
              if (a?.estado > 0 && a?.codigo == 2) {
                cantidad += 1
              }
            })
            return cantidad
          },

          component: (e) => <SView center>{!e ? <SText color="pink" bold>{e}</SText> : <SText color={STheme.color.text}>{e}</SText>}</ SView>
        },
        {
          key: "huellas-der", label: "Huella der.", width: 70,
          render: (arr) => {
            if (!arr) return null;
            return arr.find(a => a?.codigo == 2 && a?.estado > 0)
          },
          component: (e) => <SView center onPress={() => { console.log(e) }}>{!e ? <SText color="red" >No</SText> : <SText color="green">Si</SText>}</ SView>
        },
        {
          key: "huellas-der2", label: "F.Registrada huella", width: 140,
          render: (arr) => {
            if (!arr) return null;
            return arr.find(a => a?.codigo == 2)
          },
          component: (e) => <SView onPress={() => { console.log(e) }}>{!e ? <SText color="pink">Sin registro</SText> : <SText color={STheme.color.text} >{new SDate(e?.fecha_edit).toString('dd-MON-yyyy hh:mm')}</SText>}</ SView>
        },
        {
          key: "key_usuario-4", label: "Actualizar", width: 90,
          component: (e) => <SView center
            style={{ borderWidth: 1, borderColor: "yellow", borderRadius: 4, padding: 4 }}
            onPress={() => { SNavigation.navigate("Huellas", { key: e }); }}
          >{!e ? null : <SText color="yellow">Huella</SText>}</ SView>
        }
      ]}
      data={data}
    />
  }
  render() {
    return (
      <SPage title={"SERVIDOR CALISTENIA - USUARIOS ACTIVOS"} disableScroll>
        {this.getTable()}
        <FloatButtomSyncUsuario style={{ bottom: 50, }}
          onPress={() => {
            Model.usuario.Action.CLEAR_STORAGE()
          }}
        />
      </SPage >
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(DispositivoUsuarios);