import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SDate, SHr, SIcon, SImage, SInput, SLoad, SNavigation, SPopup, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Sucursal from '../../../../../Pages/Sucursal';
import punto_venta from '../../punto_venta';
import BtnSincronizar from './BtnSincronizar';
import BtnTestConnection from './BtnTestConnection';
import sucursal_usuario from '../../../../../Pages/sucursal_usuario';
import { Linking } from 'react-native';

class DeviceItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  mensajeLoad(_texto) {
    return <SView col={"xs-12"} flex center  >
      <SView col={"xs-12"} row >
        <SView flex style={{ alignItems: "flex-end" }}>
          <SText><SLoad />       </SText>
        </SView>
        <SView flex style={{ alignItems: "flex-start" }}>
          <SText color='cyan'>Cargando server {_texto}..</SText>;
        </SView>
      </SView>
    </SView>
  }

  getDataClearMolinete(dispositivo_key) {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      service: "zkteco",
      component: "dispositivo",
      type: "clearData",
      key_dispositivo: dispositivo_key,
      key_sucursal: "",
      key_usuario: "",
    }, 1000 * 60)
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  popupLimpiarMolinete(_param) {
    return <>
      <SView width={362} height={325} center row style={{ borderRadius: 8, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}>
        <SHr height={20} />
        <SInput ref={ref => this._codigo = ref} placeholder={"Ingrese código.."} col={"xs-8"} type={"text"} />
        <SHr height={4} />
        <SText col={"xs-9"} color={STheme.color.text + 66} center>⚠️Confirme si desea limpiar el molinete ZKTeco. 🔒 Por favor, introduzca el código de seguridad para continuar.</SText>
        <SButtom type="danger" onPress={() => { SPopup.close("CodigoSeguridad"); }}>Cancelar</SButtom>
        <SView width={30} ></SView>
        <SView width={30} ></SView>
        <SButtom type="success" onPress={(val) => {
          if (this._codigo.getValue() === "1234") {
            {
              alert("✅ Procesando la limpieza...");
              this.getDataClearMolinete(_param)
              SPopup.close("CodigoSeguridad");
            }
          } else {
            alert("❌ Código incorrecto, intenta de nuevo.");
          }

        }}>Confirmar</SButtom>
        <SHr height={20} />
      </SView >
    </>
  }
  render() {
    var obj = this.props.obj;
    var pv_obj = punto_venta.Actions.getByKey(obj.key_punto_venta, this.props);
    if (!pv_obj) return this.mensajeLoad("punto_venta");

    var sucs = Sucursal.Actions.getAll(this.props);
    if (!sucs) return this.mensajeLoad("sucursal");

    var suc = Object.values(sucs).find(o => o.key == pv_obj.key_sucursal);
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getAllSucursalesByKeyUsuario) return this.mensajeLoad("sucursal_usuarios");

    const sucursalesActivas = Object.values(sucs).filter((sucursal) =>
      _getAllSucursalesByKeyUsuario.some(
        (user_suc) => user_suc.key_sucursal === sucursal.key && user_suc.estado !== 0
      )
    );
    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);
    var suc = sucursalesActivas.find(o => o.key === pv_obj.key_sucursal);
    if (!suc) return <SView flex></SView>

    let horaSincronizada = new SDate(obj.ultima_modificacion).toString('dd/MM/yyyy hh:mm')
    let horaAjustada = new SDate().addHour(-4).toString('dd/MM/yyyy hh:mm:ss');

    return (
      <SView col={"xs-12"} backgroundColor={STheme.color.card} style={{ borderRadius: 4 }} center onPress={this.props.onPress}>
        <SHr />
        <SView col={"xs-11"} >
          <SView col={"xs-12"} row center>
            <SView width={50} height={50} center >
              <SImage src={SSocket.api.root + "sucursal/" + pv_obj.key_sucursal} />
            </SView>
            <SView width={8} />
            <SView flex>
              <SText color={STheme.color.lightGray}>{suc?.descripcion ?? "Sin sucursal"}</SText>
            </SView>
          </SView>
          <SHr />
          <SText fontSize={18}>{obj.descripcion}</SText>
          <SHr />
          <SText color={STheme.color.lightGray}>{obj.ip}</SText>
          <SText color={STheme.color.lightGray} fontSize={10}>{obj.mac}</SText>
          <SHr />
          <SHr height={1} color={STheme.color.card} />
          <SHr />
          <SHr />
          <SText color={STheme.color.lightGray}># Usuarios activos:{"\t"}<SText bold color='yellow'>{obj.usuarios}</SText></SText>
          <SHr />
          <SText color={STheme.color.lightGray}># Huellas activas:{"\t"}<SText color='#00ff00' bold>{obj.huellas}</SText></SText>
          <SHr />
          <SText color={STheme.color.lightGray}># Sincronizado:{"\t\t"}{(horaSincronizada <= horaAjustada) ? <SText color={STheme.color.lightGray}>{new SDate(obj.ultima_modificacion).toString('dd/MM/yyyy hh:mm')} hrs</SText> : <SText color={"#57d5ff"}>{horaSincronizada} hrs 📅 ✅</SText>}
          </SText>
          <SHr />
          <SHr />
          {/* <SHr height={1} color={STheme.color.card} />
          <SHr />
          <BtnTestConnection dispositivo={obj} sucursal={suc.descripcion} ref={ref => this._testConnection = ref} />
          <SHr /> */}

          <SHr height={1} color={STheme.color.card} />
          <BtnSincronizar dispositivo={obj} key_sucursal={suc?.key ?? ""} sucursal={suc.descripcion} testConnection={() => this._testConnection?.state?.exito} />

          {/* <SHr height={1} color={STheme.color.card} />
          <SView col={"xs-12"} center onPress={() => {
            Linking.openURL(`dispositivo/usuarios?key=` + obj.key)
          }}>
            <SHr />
            <SView col={"xs-12"} height center row>
              <SIcon name={"Eyes"} fill={STheme.color.lightGray} height={18} width={18} />
              <SView width={8} />
              <SText color={STheme.color.lightGray}>Ver usuarios activos</SText>
            </SView>
            <SHr />
          </SView> */}

          <SHr height={1} color={STheme.color.card} />
          <SHr />
          <SView col={"xs-12"} center onPress={() => {
            Linking.openURL(`/reportezkteco?key_dispositivo=` + obj.key)
          }}>
            <SHr />
            <SView col={"xs-12"} height center row>
              <SIcon name={"Eyes"} fill={STheme.color.lightGray} height={18} width={18} />
              <SView width={8} />
              <SText color={STheme.color.lightGray} bold>Ver usuarios activos</SText>
            </SView>
            <SHr />
          </SView>

          {/* <SView col={"xs-12"} center onPress={() => {
            SPopup.open({ content: this.popupLimpiarMolinete(obj.key), key: "CodigoSeguridad" });
          }}>
            <SHr />
            <SView col={"xs-12"} height center row>
              <SIcon name={"Icon2"} fill={"transparent"} stroke={STheme.color.lightGray} strokeWidth="8" height={18} width={18} />
              <SView width={8} />
              <SText color={STheme.color.lightGray} bold>Reset data ZKteco</SText>
            </SView>
            <SHr />
          </SView> */}


          {/* todo ricky dijo que lo ocultara */}
          {/* <SView col={"xs-12"} center onPress={() => {
            SNavigation.navigate("dispositivo_historico", { key: obj.key })
          }}>
            <SHr />
            <SView col={"xs-12"} height center row>
              <SIcon name={"Eyes"} fill={STheme.color.lightGray} height={18} width={18} />
              <SView width={8} />
              <SText color={STheme.color.lightGray}>Ver eventos</SText>
            </SView>
            <SHr />
          </SView>
          <SHr height={1} color={STheme.color.card} /> */}
          <SHr />
        </SView>
        <SHr />
      </SView >
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(DeviceItem);