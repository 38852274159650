import React from 'react';
import { SComponentContainer, SIcon, SNavigation, SText, STheme, SView } from 'servisofts-component';
import SSocket, { setProps } from 'servisofts-socket'
import Redux, { store } from './Redux';
import Config from "./Config";
import Assets from './Assets';
import Pages from './Pages';
import BackgroundImage from './Components/BackgroundImage';
// import SSRolesPermisos from './SSRolesPermisos';
import BarraSuperior from './Components/BarraSuperior';
import { version } from "../package.json"

setProps(Config.socket);

const App = (props) => {
  return <Redux>
    <SComponentContainer
      debug
      socket={SSocket}
      assets={Assets}
      background={<BackgroundImage />}
      inputs={Config.inputs}
      theme={{ initialTheme: "dark", themes: Config.theme }}>
      <SNavigation props={{
        // prefixes: ["https://component.servisofts.com", "component.servisofts://"],
        pages: Pages,
        title: "Calistenia-Bolivia",
        navBar: BarraSuperior
      }} />
      <SSocket store={store} identificarse={(props) => {
        var usuario = props.state.usuarioReducer.usuarioLog;
        return {
          data: usuario ? usuario : {},
          deviceKey: "as-asa-as",
        }
      }} />
      {/* <SSRolesPermisos /> */}
      <SText style={{ position: "absolute", bottom: 2, right: 24, }} fontSize={14} color={"pink"}>v{version}</SText>
      {/* <SText style={{ position: "absolute", bottom: 2, right: 2, }} fontSize={10} color={STheme.color.lightGray}>v{version}</SText> */}
    </SComponentContainer>
  </Redux>
}
export default App;